import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import { Switch } from '@bindystreet/bindystreet.kit.react';
import React from 'react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';

type Props = {
  block: IBlock;
  onToggle?: (block: IBlock) => void;
};

function BlockEditorialToggle(props: Props) {
  // Props
  const { block, onToggle } = props;

  const isEditorialBlock =
    block.heading !== undefined && block.subHeading !== undefined;

  return (
    <div className="ml-auto mt-2 flex-row flex justify-end gap-4 items-center">
      <div
        className={`font-semibold ${
          isEditorialBlock ? 'text-primaryTeal' : ''
        }`}
      >
        {block.type === BlockType.CtaBanner
          ? 'Editorial Spotlight (Link)'
          : 'Editorial Spotlight (Entity)'}
      </div>
      <div className="mt-1">
        <Switch
          checked={isEditorialBlock}
          onChange={() => {
            onToggle?.({
              ...block,
              heading: isEditorialBlock ? undefined : '',
              subHeading: isEditorialBlock ? undefined : '',
              linkMetadata: isEditorialBlock ? undefined : {}
            });
          }}
        />
      </div>
    </div>
  );
}

export default BlockEditorialToggle;
