import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { Checkbox, Input } from '@bindystreet/bindystreet.kit.react';
import React from 'react';
import { blockNameMaxLength } from './BlockManagementModal';
import BlockLayoutSelector from './BlockLayoutSelector';
import BlockCardSizeSelector from './BlockCardSizeSelector';
import { BlockType, ContentType } from 'Colugo/interfaces/lobby/discover/enums';
import BlockCategorySelector from './BlockCategorySelector';
import BlockTagsSelector from './BlockTagsSelector';
import { toast } from 'react-toastify';

type Props = {
  block: IBlock;
  isScenario: boolean;
  isBlockPage: boolean;
  shouldUpdateSuggestedBlockNearMe?: boolean;
  onModify?: (block: IBlock) => void;
};

function BlockDataForm(props: Props) {
  // Props
  const {
    block,
    isScenario,
    isBlockPage,
    shouldUpdateSuggestedBlockNearMe,
    onModify
  } = props;

  const isCtaBanner = block.type === BlockType.CtaBanner;
  const isEditorialBlock =
    block.heading !== undefined && block.subHeading !== undefined;
  const isGamesBlock =
    block.type === BlockType.NewGuides ||
    block.type === BlockType.SuggestedGuides ||
    block.type === BlockType.TopGuides ||
    block.type === BlockType.TrendingGuides;
  const isSuggestedBlock =
    block.type === BlockType.SuggestedEvents ||
    block.type === BlockType.SuggestedListings ||
    block.type === BlockType.SuggestedGuides;
  const isTrendingPopularBlock =
    block.type === BlockType.Trending || block.type === BlockType.Popular;

  const blockCountError =
    block.count && isEditorialBlock && block.count < 0
      ? 'Count must be non-negative if editorial block'
      : (block.count || 0) > 20
      ? 'Count must not be more than 20'
      : '';

  function handleUpdateNearMe() {
    if (shouldUpdateSuggestedBlockNearMe) {
      onModify?.({
        ...block,
        isLocationBased: !block.isLocationBased
      });
      return;
    } else {
      toast.warn(
        `Suggested block is not location based and contains manually added items so the near me value can't be updated`
      );
    }
    return;
  }

  function changeIsTourist() {
    switch (block.contentType) {
      case ContentType.Tourist:
        onModify?.({
          ...block,
          contentType: ContentType.Local
        });
        break;
      default:
      case ContentType.Local:
        onModify?.({
          ...block,
          contentType: ContentType.Tourist
        });
        break;
    }
  }

  return (
    <div>
      <div>
        <span className="font-semibold">{'Block Name:'}</span>
        <Input
          autoFocus={true}
          value={block?.name}
          onChange={(e) => {
            onModify?.({
              ...block,
              name: e.currentTarget.value
            });
          }}
          maxCharacterCount={blockNameMaxLength}
        />
      </div>
      {isScenario && block.type !== undefined && (
        <div className="flex-col">
          <span className="flex text-md">
            For Headings and Subheadings, use the format
            &#123;&#123;user&#125;&#125; to display user names.
          </span>
          <span className="flex text-md">
            E.g. Good morning &#123;&#123;user&#125;&#125;
          </span>
          <span className="font-semibold">Block Heading:</span>
          <Input
            autoFocus={true}
            value={block.heading}
            onChange={(e) => {
              onModify?.({
                ...block,
                heading: e.currentTarget.value
              });
            }}
          />
          <span className="font-semibold flex">{'Block Subheading:'}</span>
          <Input
            autoFocus={true}
            value={block.subHeading}
            onChange={(e) => {
              onModify?.({
                ...block,
                subHeading: e.currentTarget.value
              });
            }}
          />
        </div>
      )}
      {!isScenario && !isCtaBanner && !isEditorialBlock && (
        <BlockLayoutSelector block={block} onChange={onModify} />
      )}
      {!isScenario && !isEditorialBlock && (
        <BlockCardSizeSelector block={block} onChange={onModify} />
      )}
      {(isGamesBlock || (isSuggestedBlock && isBlockPage)) && (
        <BlockCategorySelector block={block} onChange={onModify} />
      )}
      {isSuggestedBlock && isBlockPage && (
        <BlockTagsSelector block={block} onChange={onModify} />
      )}

      {(isGamesBlock ||
        isTrendingPopularBlock ||
        block.type === BlockType.Following ||
        (isSuggestedBlock && isBlockPage)) && (
        <div className="">
          <span className="font-semibold">{'Near me:'}</span>
          <Checkbox
            size="lg"
            selectedColour="primaryButton"
            onChange={handleUpdateNearMe}
            checked={block.isLocationBased || false}
          />
        </div>
      )}

      {!isScenario && (
        <div className="mt-2 w-36">
          <span className="font-semibold">{'Tourist specific:'}</span>
          <Checkbox
            size="lg"
            selectedColour="primaryButton"
            onChange={changeIsTourist}
            checked={block.contentType === ContentType.Tourist}
          />
        </div>
      )}
      {!isCtaBanner && !isScenario && (
        <div>
          <div className="font-semibold">{'Count (required):'}</div>
          <Input
            value={block.count?.toString() || ''}
            onChange={(e) => {
              onModify?.({
                ...block,
                count: parseInt(e.currentTarget.value, 10) || 0
              });
            }}
            error={blockCountError}
          />
        </div>
      )}
    </div>
  );
}

export default BlockDataForm;
