import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IUser } from 'Colugo/interfaces/identity';
import { useReqGetUser } from 'Colugo/operations/identity';
import CommunityEditor from 'component/admin/advertLocation/CommunityEditor';
import MapSearchCsEditor from 'component/admin/advertLocation/MapSearchCsEditor';
import CategoryContainer from 'component/admin/category/CategoryContainer';
import CombosContainer from 'component/admin/combos/CombosContainer';
import ExploreContainer from 'component/admin/explore/ExploreContainer';
import FilterGroupsContainer from 'component/admin/filterGroups/FilterGroupsContainer';
import HomeContainer from 'component/admin/home/HomeContainer';
import InterestScoreContainer from 'component/admin/interest/InterestScoreContainer';
import ManifestContainer from 'component/admin/manifest/ManifestContainer';
import ModerationContainer from 'component/admin/moderation/ModerationContainer';
import UserContainer from 'component/admin/player/UserContainer';
import ScenarioContainer from 'component/admin/scenario/ScenarioContainer';
import SecretContainer from 'component/admin/secret/SecretContainer';
import SpiritCustomizationContainer from 'component/admin/spiritCustomization/SpiritCustomizationContainer';
import BlockContainer from 'component/admin/subPageBlock/BlockContainer';
import SurveyContainer from 'component/admin/survey/SurveyContainer';
import TagContainer from 'component/admin/tags/TagContainer';
import VideoContainer from 'component/admin/videos/VideosContainer';
import DashboardHeader from 'component/dashboard/DashboardHeader';
import DashboardSidebar from 'component/dashboard/DashboardSidebar';
import { AuthContext } from 'provider/auth/authProvider';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { localStorage_Me as localStorage_User } from 'utility/constants/constants';
import localStore from 'utility/general/localStore';

type Props = {};

const DashboardContainer: React.FC<Props> = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { handleSignout }: { handleSignout: any; setMe: any; user: IUser } =
    useContext(AuthContext);
  const [signOut, setSignout] = useState<{
    toSignOut: boolean;
    message: string | undefined;
  }>({ toSignOut: false, message: undefined });

  useEffect(() => {
    if (signOut.toSignOut) {
      handleSignout(signOut.message);
    }
  }, [handleSignout, signOut]);

  const isTokenAvailable = localStorage.getItem('token');

  const { data: user, isError, isLoading } = useReqGetUser(!!isTokenAvailable);

  if (isLoading) {
    return (
      <div className="absolute w-full h-full">
        <Spinner expand />
      </div>
    );
  }

  if (isError) {
    const message =
      'Unable to sign you in due to error retrieving your profile.';
    console.info('Refresh token has been wiped, resetting status');

    !signOut.toSignOut &&
      setSignout({
        toSignOut: true,
        message
      });
    return <></>;
  }

  if (
    !user?.creator?.venues ||
    user.creator.venues?.length === 0 ||
    !user.creator.venues[0].id
  ) {
    !signOut.toSignOut &&
      setSignout({
        toSignOut: true,
        message:
          'You are not part of an existing venue. Please contact your administrator.'
      });
    return <></>;
  }

  //TODO move this into useGetUser hook
  const hydrateUserState = (user: IUser): boolean => {
    try {
      const stringMe = localStore.getItem(localStorage_User);
      if (stringMe !== null) {
        user = JSON.parse(stringMe);
      }
      localStore.setItem(localStorage_User, JSON.stringify(user));
    } catch (ex) {
      console.error(ex);
      return false;
    }
    return true;
  };

  if (!hydrateUserState(user)) {
    setSignout({
      toSignOut: true,
      message:
        'Unable to retrieve your user profile. please contact us at support@bindystreet.com if the problem persists.'
    });
  }

  return (
    <div className="flex h-auto min-h-screen bg-gray-200">
      <DashboardSidebar user={user} />

      <div className="flex-1 flex flex-col overflow-hidden">
        <DashboardHeader
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
          onClickSignout={() => {
            handleSignout();
          }}
          user={user}
        />

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
          <div className="h-full mx-auto">
            <Switch>
              <Route path="/survey" render={() => <SurveyContainer />} />
              <Route path="/blocks/*" render={() => <BlockContainer />} />
              <Route path="/secret" render={() => <SecretContainer />} />
              <Route path="/videos" render={() => <VideoContainer />} />
              <Route
                path="/filterGroups"
                render={() => <FilterGroupsContainer />}
              />
              <Route path="/combos" render={() => <CombosContainer />} />
              <Route path="/tags" render={() => <TagContainer />} />
              <Route path="/discovery" render={() => <HomeContainer />} />
              <Route path="/explore" render={() => <ExploreContainer />} />
              <Route path="/scenario" render={() => <ScenarioContainer />} />
              <Route path="/mapSearchCs" render={() => <MapSearchCsEditor />} />
              <Route path="/community" render={() => <CommunityEditor />} />
              <Route
                path="/interestScore"
                render={() => <InterestScoreContainer />}
              />
              <Route path="/manifest" render={() => <ManifestContainer />} />
              <Route
                path="/moderation"
                render={() => <ModerationContainer />}
              />
              <Route path="/users" render={() => <UserContainer />} />
              <Route
                path="/spiritCustomization"
                render={() => <SpiritCustomizationContainer />}
              />
              <Route path="/categories" render={() => <CategoryContainer />} />
              <Route path="/" render={() => <SurveyContainer />} />
            </Switch>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardContainer;
