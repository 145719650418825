import { FlyoutMenu, FlyoutMenuItem } from '@bindystreet/bindystreet.kit.react';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';

type Props = {
  flyoutMenuItems: FlyoutMenuItem[];
  block: IBlock;
  onChange?: (item: FlyoutMenuItem) => void;
};

function BlockTypeSelector(props: Props) {
  // Props
  const { flyoutMenuItems, block, onChange } = props;

  // State
  const [isTypeSelectorOpen, setIsTypeSelectorOpen] = useState<boolean>(false);

  function _onChange(item: FlyoutMenuItem) {
    onChange?.(item);
  }

  return (
    <div>
      <span className="font-semibold ml-1">{'Block Types'}</span>
      <div className="mt-1">
        <FlyoutMenu
          // NOTE: even though activeLabel is deprecated, kindly use it here as the keys are not in sync when filtered out
          activeLabel={
            flyoutMenuItems.find(
              (o) => o.label === BlockType[block.type || BlockType.NewGuides]
            )?.label
          }
          isEqual={isEqual}
          size="md"
          isMenuOpen={isTypeSelectorOpen}
          setIsMenuOpen={setIsTypeSelectorOpen}
          items={flyoutMenuItems}
          onChange={_onChange}
        />
      </div>
    </div>
  );
}

export default BlockTypeSelector;
