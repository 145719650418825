import ReportSource from 'Colugo/interfaces/moderation/enums/ReportSource';
import ConfirmDeletePopup, {
  skinType
} from 'component/utility/ConfirmDeletePopup';
import { useState } from 'react';
import TabComponent from '../survey/TabComponent';
import { ITab } from '../survey/SurveyTabList';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import UserReportsTab from './tabs/UserReportsTab';

const ModerationTabs = [
  {
    name: 'Reported User',
    path: '/reportedUser'
  },
  {
    name: 'Reported Moments',
    path: '/reportedMoments'
  }
];

export default function ModerationContainer() {
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [confirmationState, setConfirmationState] = useState<
    | {
        onSubmit: () => void;
        title: string;
        skin: skinType;
      }
    | undefined
  >(undefined);

  const currentTabName = ModerationTabs.find((tab) =>
    location.pathname.includes(tab.path)
  )?.name;

  const containerTitle = `Reporting`;

  function handleOnClickTab(tab: ITab) {
    history.push(`${url}${tab.path}`);
  }

  function closeModal() {
    setConfirmationState(undefined);
  }

  const resolutionModal = (
    <ConfirmDeletePopup
      isModalOpen={confirmationState !== undefined}
      onSubmit={() => {
        confirmationState?.onSubmit();
        setConfirmationState(undefined);
      }}
      closeModal={closeModal}
      title={confirmationState?.title || ''}
      skin={confirmationState?.skin || 'resolveUserReport'}
    />
  );

  return (
    <div className="h-full overflow-hidden container ml-32">
      <div className="mx-auto flex flex-col">
        <div className="w-full h-full">
          <div className="flex justify-start mt-4 heading-bold-xl-onSurface">
            {containerTitle}
          </div>
          {resolutionModal}
          <div className="flex flex-row mt-4 w-full">
            {ModerationTabs.map((tab, i) => (
              <TabComponent
                key={i}
                activeTabName={currentTabName!}
                tabName={tab.name}
                index={i}
                tab={tab}
                onClickTab={() => handleOnClickTab(tab)}
              />
            ))}
          </div>
          <div
            className="flex flex-col items-start w-full h-full border-t-2 border-surfaceOutline"
            style={{ marginTop: -2 }}
          >
            <Switch>
              <Route
                path={`${url}/reportedUser`}
                children={() => (
                  <UserReportsTab
                    reportType={ReportSource.User}
                    onConfirm={setConfirmationState}
                  />
                )}
              />
              <Route
                path={`${url}/reportedMoments`}
                children={() => (
                  <UserReportsTab
                    reportType={ReportSource.Moment}
                    onConfirm={setConfirmationState}
                  />
                )}
              />
              <Redirect to="/moderation/reportedUser" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
