export interface ITab {
  name: string;
  path: string;
}

export function SurveyTabList(activeStepName?: string): ITab[] {
  return [
    {
      name: 'Survey Summary',
      path: '/summary'
    },
    {
      name: 'Survey Submissions',
      path: '/submissions'
    },
    {
      name: 'Bug Entries',
      path: '/bugentries'
    },
    {
      name: 'Suggestions Entries',
      path: '/suggestionsEntries'
    }
  ];
}
