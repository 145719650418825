import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { MdClose } from 'react-icons/md';
import { RiDeleteBin2Line, RiFileUploadFill } from 'react-icons/ri';
import {
  Button,
  getTailwindColour,
  Input,
  Modal
} from '@bindystreet/bindystreet.kit.react';
import { modalRoot } from 'utility/constants/constants';
import { usePortal } from 'utility/hooks/usePortal';

export type skinType =
  | 'spot'
  | 'zone'
  | 'game'
  | 'quiz'
  | 'template'
  | 'question'
  | 'importQuiz'
  | 'importSpotDetails'
  | 'trail'
  | 'category'
  | 'tag'
  | 'discoverSubPage'
  | 'publishAllGames'
  | 'spirit customization'
  | 'block'
  | 'filter group'
  | 'combo'
  | 'resolveUserReport'
  | 'suspendUser'
  | 'reindexAllListings'
  | 'reindexAllUsers'
  | 'reindexAllCollections'
  | 'banUser'
  | 'deleteMoment';

type Props = {
  isModalOpen: boolean;
  onSubmit: () => void;
  closeModal: () => void;
  skin: skinType;
  title: string;
  itemNumber?: number;
};

const ConfirmDeletePopup: React.FC<Props> = (props) => {
  const {
    isModalOpen,
    closeModal,
    onSubmit,
    skin,
    title,
    itemNumber,
    children
  } = props;
  const [isGameAndDelete, setIsGameAndDelete] = useState('');
  const target = usePortal(modalRoot);

  const message = getMessage(title, skin, itemNumber);

  const getDeleteMessage = () => {
    switch (skin) {
      case 'spot':
        return itemNumber === 1 || !itemNumber
          ? `Yes, delete the spot`
          : `Yes, delete ${itemNumber} spots`;
      case 'game':
      case 'category':
      case 'block':
      case 'spirit customization':
      case 'combo':
      case 'filter group':
      case 'tag':
        return `Yes, delete my ${skin}`;
      case 'discoverSubPage':
        return `Yes, delete my discover sub page`;
      case 'zone':
        return `Yes, delete zone and ${itemNumber} spots`;
      case 'trail':
        return `Yes, delete trail and its data`;
      case 'question':
        return `Yes, delete ${title.toLowerCase()}`;
      case 'publishAllGames':
        return `Yes, republish all my guides. I understand this cannot be undone.`;
      case 'reindexAllListings':
        return `Yes, reindex all listings & events.`;
      case 'reindexAllUsers':
        return `Yes, reindex all users.`;
      case 'reindexAllCollections':
        return `Yes, reindex all collections.`;
      case 'resolveUserReport':
        return `Yes, resolve user report.`;
      case 'suspendUser':
        return 'Yes, suspend user';
      case 'banUser':
        return 'Yes, ban user';
      case 'deleteMoment':
        return 'Yes, delete Moment';
      default:
        return 'Delete';
    }
  };

  const deleteMessage = getDeleteMessage();

  const GameCheckInput = (
    <Input
      value={isGameAndDelete}
      size="lg"
      placeholder='Type "delete" here'
      onChange={(e) => setIsGameAndDelete(e.currentTarget.value)}
      textSize="text-lg"
    />
  );

  const modal = (
    <Modal
      isMenuOpen={isModalOpen}
      position="center"
      size="lg"
      className="rounded-2xl border border-gray-400"
      overlay={true}
      onCloseModal={closeModal}
      zIndex={52}
    >
      <div className="w-full mt-8 mb-8 ">
        <RiDeleteBin2Line
          className="m-auto"
          size="64"
          color={getTailwindColour('error1')}
        />
      </div>

      <div
        className="text-center font-bold mb-2 text-black uppercase"
        style={{ fontSize: '22px', lineHeight: '26.4px' }}
      >
        {skin === 'spot' && itemNumber && itemNumber !== 1
          ? `Delete ${itemNumber} spots`
          : skin === 'importQuiz' ||
            skin === 'importSpotDetails' ||
            skin === 'publishAllGames' ||
            skin === 'reindexAllListings' ||
            skin === 'reindexAllUsers' ||
            skin === 'reindexAllCollections' ||
            skin === 'resolveUserReport' ||
            skin === 'suspendUser' ||
            skin === 'banUser' ||
            skin === 'deleteMoment'
          ? title
          : `Delete '${title}' `}
      </div>
      <div className="text-center text-gray-700 text-xl mx-auto">{message}</div>

      {skin === 'game' && (
        <div>
          <div className="mx-auto w-4/5 flex flex-col">
            <div className="bg-stroke1 mt-8 w-full" style={{ height: '1px' }} />
            <span className="text-black my-2 text-center text-xl">
              Type "delete" to confirm
            </span>
            {GameCheckInput}
          </div>
        </div>
      )}

      <div className="my-8 flex flex-row">
        <div className="flex-grow"></div>
        <div className="flex flex-row mr-3">
          <Button skin={'secondary'} size={'lg'} onClick={closeModal}>
            <MdClose className="mt-0.5 ml-1 mr-2" size="23" />
            <span className="font-bold mr-1 text-lg">Cancel</span>
          </Button>
        </div>
        <div className="flex flex-row ml-3 ">
          <Button
            onClick={onSubmit}
            disabled={skin === 'game' && isGameAndDelete !== 'delete'}
            skin={'error'}
            size={'lg'}
            className={'h-full'}
          >
            {skin !== 'importQuiz' && skin !== 'importSpotDetails' && (
              <div className={`flex flex-row font-bold mr-2 text-lg`}>
                <div className="flex flex-col">
                  <RiDeleteBin2Line className="my-auto mx-2" size="22" />
                </div>
                {deleteMessage}
              </div>
            )}
            {(skin === 'importQuiz' || skin === 'importSpotDetails') && (
              <div className="flex flex-row">
                <RiFileUploadFill className="mt-.5 mx-2" size="22" />
                <div>Import csv</div>
              </div>
            )}
          </Button>
        </div>
        <div className="flex-grow"></div>
      </div>
      {children}
    </Modal>
  );

  return createPortal(modal, target);
};

const getMessage = (
  title: string,
  skin: skinType,
  itemNumber?: number
): JSX.Element => {
  const wrapperClass =
    'bg-error3 w-8/12 py-4 rounded-lg mt-6 px-1 mx-auto font-bold';
  const spanClass = 'inline-block text-error1';

  switch (skin) {
    case 'spot':
      return getSpotElement(wrapperClass, spanClass, title, itemNumber);
    case 'quiz':
    case 'zone':
    case 'template':
    case 'category':
    case 'block':
    case 'spirit customization':
    case 'discoverSubPage':
    case 'game':
      return getElement(wrapperClass, spanClass, skin, title);
    case 'question':
      return getQuestionElement(title);
    case 'publishAllGames':
      return getPublishAllGamesTextElement();
    case 'reindexAllListings':
      return getReindexAllListingsElement();
    case 'reindexAllUsers':
      return getReindexAllUsersElement();
    case 'reindexAllCollections':
      return getReindexAllCollectionsElement();
    case 'trail':
      return getTrailElement(title);
    case 'importQuiz':
    case 'importSpotDetails':
      return getCsvImportElement(wrapperClass, spanClass, skin);
    case 'tag':
      return getTagElement(wrapperClass, spanClass);
    case 'filter group':
      return getFilterGroupElement(wrapperClass, spanClass);
    case 'combo':
      return getComboElement(wrapperClass, spanClass);
    default:
      return <></>;
  }
};

const getSpotElement = (
  wrapperClass: string,
  spanClass: string,
  title: string,
  itemNumber?: number
) => {
  const getDeleteConfirmationMessage = (title: string, itemNumber?: number) => {
    return (
      <span className="inline-block mx-24 text-black">
        You are about to permanently delete{' '}
        {itemNumber && itemNumber > 1 ? '' : 'the spot'} '{title}'
        {itemNumber &&
          itemNumber > 1 &&
          (itemNumber === 2
            ? ` and ${itemNumber - 1} other spot.`
            : ` and ${itemNumber! - 1} other spots.`)}
      </span>
    );
  };

  return (
    <div>
      {getDeleteConfirmationMessage(title, itemNumber)}
      <div className={wrapperClass}>
        {!itemNumber || itemNumber === 1 ? (
          <span className={spanClass}>This spot will lose all its details</span>
        ) : (
          <span className={spanClass}>
            {itemNumber} spots will lose all their details
          </span>
        )}
      </div>
    </div>
  );
};

const getElement = (
  wrapperClass: string,
  spanClass: string,
  skin: string,
  title: string
) => {
  let subtitleText =
    skin === 'template'
      ? 'You are about to permanently clear all the data in this spot tile by switching templates.'
      : ` You are about to permanently delete the ${skin} '${title}'. `;
  return (
    <div>
      <span className="inline-block mx-24 text-black">{subtitleText}</span>
      {skin !== 'spirit customization' && (
        <div className={wrapperClass}>
          {skin === 'game' ? (
            <div>
              <span className={spanClass}>
                You'll lose all the data in this game!
              </span>
              <span className={spanClass}>This action cannot be undone.</span>
            </div>
          ) : (
            <span className={spanClass}>
              Items will no longer be associated to this {skin}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

const getCsvImportElement = (
  wrapperClass: string,
  spanClass: string,
  skin: 'importQuiz' | 'importSpotDetails'
) => {
  return (
    <div className="mx-auto w-80">
      <span className="inline-block">
        {skin === 'importQuiz'
          ? 'You are about to overwrite previously added quizzes'
          : 'You are about to overwrite spot information'}
        .
      </span>
      <div className={wrapperClass}>
        <span className={spanClass}>
          {skin === 'importQuiz'
            ? 'All quizzes will be lost'
            : 'Selected spots will be overwritten'}
        </span>
      </div>
    </div>
  );
};

const getTagElement = (wrapperClass: string, spanClass: string) => {
  return (
    <div className="mx-auto w-80">
      <span className="inline-block">You are about to delete a tag.</span>
      <div className={wrapperClass}>
        <span className={spanClass}>
          This is irreversible and will affect player interest in this tag.
        </span>
      </div>
    </div>
  );
};

const getFilterGroupElement = (wrapperClass: string, spanClass: string) => {
  return (
    <div className="mx-auto w-80">
      <span className="inline-block">
        You are about to delete a filter group.
      </span>
      <div className={wrapperClass}>
        <span className={spanClass}>
          This filter group will also lose all categories and tags assigned.
        </span>
      </div>
    </div>
  );
};

const getComboElement = (wrapperClass: string, spanClass: string) => {
  return (
    <div className="mx-auto w-80">
      <span className="inline-block">You are about to delete a combo.</span>
      <div className={wrapperClass}>
        <span className={spanClass}>
          This combo will also lose all filterGroups assigned.
        </span>
      </div>
    </div>
  );
};

const getTrailElement = (title: string) => {
  return (
    <div className="w-full mx-auto">
      <span className="inline-block w-80">
        You are about to permanently delete the trail '{title}'
      </span>
      <div className="bg-error3 py-4 rounded-lg mt-6 text-error1 w-3/4 mx-auto font-bold">
        You'll lose mapped data and assigned spots
      </div>
    </div>
  );
};

const getPublishAllGamesTextElement = () => {
  return (
    <div className="w-full mx-auto mt-12 text-wrap">
      <span className="bg-error3 p-4 rounded-lg text-error1 w-7/12 mx-auto font-bold">
        You are about to republish all published guides
      </span>
    </div>
  );
};

const getReindexAllListingsElement = () => {
  return (
    <div className="w-full mx-auto mt-12 text-wrap">
      <span className="bg-error3 p-4 rounded-lg text-error1 w-7/12 mx-auto font-bold">
        You are about to reindex all listings & events.
      </span>
    </div>
  );
};

const getReindexAllUsersElement = () => {
  return (
    <div className="w-full mx-auto mt-12 text-wrap">
      <span className="bg-error3 p-4 rounded-lg text-error1 w-7/12 mx-auto font-bold">
        You are about to reindex all users.
      </span>
    </div>
  );
};

const getReindexAllCollectionsElement = () => {
  return (
    <div className="w-full mx-auto mt-12 text-wrap">
      <span className="bg-error3 p-4 rounded-lg text-error1 w-7/12 mx-auto font-bold">
        You are about to reindex all collection.
      </span>
    </div>
  );
};

const getQuestionElement = (title: string) => {
  return (
    <div className="w-full mx-auto">
      <span className="inline-block w-80">
        You are about to delete '{title.toLowerCase()}' and its assigned answers
      </span>
      <div className="bg-error3 py-4 rounded-lg mt-6 text-error1 w-7/12 mx-auto font-bold">
        You'll lose the assigned answers
      </div>
    </div>
  );
};

export default ConfirmDeletePopup;
