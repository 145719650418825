import { IUserReport } from 'Colugo/interfaces/moderation/IUserReport';
import React, { useState } from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';

import { IoMdCheckmark } from 'react-icons/io';
import ReportSource from 'Colugo/interfaces/moderation/enums/ReportSource';

type Props = {
  sourceType: ReportSource;
  tableColumns: Column<IUserReport>[];
  userReports: IUserReport[];
  isMutationLoading?: boolean;
  onResolveReport: (userReportId: string) => void;
  onSelectReport: (userReport: IUserReport) => void;
};

function UserReportTable(props: Props) {
  const {
    sourceType,
    userReports,
    tableColumns,
    onResolveReport,
    onSelectReport
  } = props;
  const [rowHovered, setRowHovered] = useState(-1);
  const [selectedReport, setSelectedReport] = useState<
    IUserReport | undefined
  >();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: tableColumns,
        data: userReports
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useFlexLayout
    );

  function getPrettyType() {
    switch (sourceType) {
      case ReportSource.User:
        return 'Users';
      case ReportSource.Moment:
        return 'Moments';
    }
  }

  const table = (
    <div className="overflow-y-auto flex flex-1 h-full">
      {!userReports || userReports.length === 0 ? (
        <div className="w-full flex flex-col flex-1 bg-surfaceContainerLow rounded-lg h-full p-6">
          <div>{`No Reported ${getPrettyType()} to deal with, come back later.`}</div>
        </div>
      ) : (
        <div {...getTableProps()} className="w-full">
          <div>{userReportTableHeaderConfig(headerGroups)}</div>
          <div>
            {userReportTableRowsConfig(
              rowHovered,
              setRowHovered,
              getTableBodyProps,
              rows,
              prepareRow,
              selectedReport,
              onResolveReport,
              (userReport) => {
                setSelectedReport(userReport);
                onSelectReport(userReport);
              }
            )}
          </div>
        </div>
      )}
    </div>
  );

  return <div className="mt-4 flex flex-1">{table}</div>;
}

export default UserReportTable;

const userReportTableHeaderConfig = (
  headerGroups: HeaderGroup<IUserReport>[]
) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      key={'group' + i}
      className="h-12 font-bold tracking-wider pl-4"
    >
      {headerGroup.headers.map((column, j) => {
        return (
          <div className={`flex-row w-full`} key={j}>
            {column.Header!.toString() !== 'delete' && (
              <div
                className={
                  'flex-row flex align-center text-xs text-left xl:text-sm my-auto'
                }
              >
                <div>{column.render('Header')}</div>
                <div>
                  {column.Header!.toString() === '' ? (
                    <> </>
                  ) : column.isSorted ? (
                    column.isSortedDesc ? (
                      <FaSortDown className="text-black" />
                    ) : (
                      <FaSortUp className="text-black mt-auto" />
                    )
                  ) : (
                    <FaSortDown className="text-gray-300" />
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  ));
};

function formatDate(date: Date) {
  const time = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  return `${time} | ${date.toLocaleDateString([], {
    day: '2-digit',
    month: 'short'
  })}`;
}

const userReportTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<IUserReport> | undefined
  ) => TableBodyProps,
  rows: Row<IUserReport>[],
  prepareRow: (row: Row<IUserReport>) => void,
  selectedReport: IUserReport | undefined,
  onResolveReport: (userReport: string) => void,
  onSelectUserReport: (userReport: IUserReport) => void
) => (
  <div
    style={{ height: `calc(${getMaxViewHeight()}` }}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      let bgColor: string = i % 2 ? 'bg-transparent' : 'bg-surfaceContainerLow';
      const entityDeleted = !!row.cells.find(
        (c) => c.column.id.indexOf('deletedAt') > 0
      )?.value;
      bgColor = entityDeleted ? 'bg-alert2' : bgColor;
      return (
        <div
          className={`flex relative rounded-md ${bgColor} hover:shadow-lg h-20`}
          onMouseEnter={() => setRowHovered(i)}
          onMouseLeave={() => {
            if (rowHovered === i) {
              setRowHovered(-1);
            }
          }}
          onClick={() => onSelectUserReport(row.original)}
          key={'row' + i}
        >
          {(rowHovered === i || selectedReport?.id === row.original.id) && (
            <div className="border border-primary bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l bg-primary absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          <div
            className={
              'flex relative align-middle w-full h-full pl-4 cursor-pointer'
            }
          >
            {row.cells.map((cell, index) => {
              let classNames = `my-1 text-left`;
              if (cell.column.Header?.toString() === 'delete') {
                if (cell.column.id === 'id') {
                  return (
                    <div
                      key={'cell' + index}
                      className="flex flex-row flex-1 items-center justify-end"
                    >
                      <IoMdCheckmark
                        className={`flex text-4xl cursor-pointer text-onSurfaceVariant mr-4`}
                        onClick={() => {
                          onResolveReport(cell.value);
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div
                    key={'cell' + index}
                    className="my-auto text-error1 font-bold"
                  >
                    {entityDeleted ? 'DELETED' : ''}{' '}
                  </div>
                );
              }

              return (
                <div
                  key={'cell' + index}
                  className={`${classNames} my-auto flex-1`}
                >
                  {cell.column.Header?.toString() === 'Date Submitted' ? (
                    <div>{formatDate(new Date(cell.value as string))}</div>
                  ) : (
                    <>{cell.render('Cell')}</>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    })}
  </div>
);
