export enum ResolutionStatus {
  None = 0,
  Resolved = 1 << 0,
  UserSuspended = 1 << 1,
  UserBanned = 1 << 2,
  MomentDeleted = 1 << 3,
  CommentDeleted = 1 << 4
}

export default ResolutionStatus;
