import { useCallback } from 'react';

export const useSearchVideos = (
  videos: any[],
  setAllVideos: (videos: any[]) => void
) => {
  return useCallback(
    (queryText: string) => {
      const value = queryText.toLowerCase();
      if (value && videos) {
        const filteredVideos = videos.filter(
          (item) =>
            item.title?.toLowerCase().includes(value) ||
            item.caption?.toLowerCase().includes(value) ||
            item.user.email.toLowerCase().includes(value) ||
            item.user.firstName?.toLowerCase().includes(value) ||
            item.user.lastName?.toLowerCase().includes(value) ||
            item.user.username?.toLowerCase().includes(value) ||
            item.user.displayName?.toLowerCase().includes(value)
        );
        setAllVideos(filteredVideos);
      } else {
        setAllVideos(videos);
      }
    },
    [videos, setAllVideos]
  );
};
