import {
  Button,
  CardHeader,
  FlyoutMenu,
  FlyoutMenuItem,
  Input
} from '@bindystreet/bindystreet.kit.react';
import RankedResultItemConverter from 'Colugo/interfaces/converters/RankedResultItemConverter';
import { IGame, ISpotVersion, IZoneVersion } from 'Colugo/interfaces/games';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import {
  CtaBannerMediaType,
  CtaBannerType,
  EditorialBlockMediaType
} from 'Colugo/interfaces/lobby/discover/blocks/IBlock';
import {
  BlockType,
  CardSize,
  ContentType,
  Layout
} from 'Colugo/interfaces/lobby/discover/enums';
import { ActionType } from 'Colugo/interfaces/lobby/discover/enums/ActionType';
import {
  ManualBlockNames,
  OmittedBlockTypes
} from 'Colugo/interfaces/lobby/discover/enums/BlockType';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import {
  Filter,
  ISearchRequest,
  Order
} from 'Colugo/interfaces/search/ISearchRequest';
import { ZoneOperations } from 'Colugo/operations';
import { useReqGetGuide } from 'Colugo/operations/guides';
import SearchOperations from 'Colugo/operations/search/SearchOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { MutatorCallback } from 'swr/dist/types';
import { container } from 'tsyringe';
import BlockTypeSelector from '../blockManagementModal/BlockTypeSelector';
import GameTable, { gameTableColumns } from '../blocks/BlocksGameTable';
import EditorialBlock, {
  MAX_HEADING_LENGTH,
  MAX_SUBHEADING_LENGTH
} from '../blocks/EditorialBlock';
import CtaBannerManage from '../blocks/CtaBannerManage';
import BlockItemAdded from '../blocks/BlockItemAdded';
import ExistingBlockSelector from './ExistingBlockSelector';
import BlockEditorialToggle from './BlockEditorialToggle';
import BlockDataForm from './BlockDataForm';

//TODO: Add videos group management here also
const zoneOperations = container.resolve(ZoneOperations);
const searchOperations = container.resolve(SearchOperations);
const rankedResultItemConverter = container.resolve(RankedResultItemConverter);

export const ctaBannerTitleLength = 45;
export const ctaBannerDescriptionLength = 160;
export const ctaBannerLinkTextLength = 30;
export const ctaBannerColourHexCodeLength = 7;
export const blockNameMaxLength = 35;

export const listingBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.ListingsBlock, Dippy.Models';
export const guideBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.GamesBlock, Dippy.Models';
export const ctaBannerBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.CtaBannerBlock, Dippy.Models';
export const spotBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.SpotsBlock, Dippy.Models';
export const eventBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.EventsBlock, Dippy.Models';
export const videoBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.VideosBlock, Dippy.Models';
export const entityBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.EntitiesBlock, Dippy.Models';
export const baseBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.BaseBlock, Dippy.Models';

type Props = {
  title?: string;
  block?: IBlock;
  isBlockPage: boolean;
  onCreateDiscoverBlock?: (discoverBlock: IBlock) => void;
  setIsModalOpen: (isOpen: boolean) => void;
  onClickUpdateSubmit?: (block: IBlock) => void;
  onClickCloseUpdateModal?: () => void;
  shouldUpdateSuggestedBlockNearMe?: boolean;
  guideItems?: ISpotVersion[];
  updateItemsForGuideSpots?: (
    data?:
      | ISpotVersion[]
      | Promise<ISpotVersion[]>
      | MutatorCallback<ISpotVersion[]>
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<ISpotVersion[] | undefined>;
  isScenario?: boolean;
};

function BlockManagementModal(props: Props) {
  const {
    title,
    block,
    onCreateDiscoverBlock,
    setIsModalOpen,
    isBlockPage,
    onClickUpdateSubmit,
    guideItems,
    shouldUpdateSuggestedBlockNearMe = true,
    updateItemsForGuideSpots,
    onClickCloseUpdateModal,
    isScenario = false
  } = props;

  const isEditing = block != null;
  const getInitialState = () => {
    return isEditing
      ? block
      : {
          $type: '',
          name: '',
          cardSize: CardSize.Small,
          layout: Layout.Horizontal,
          contentType: ContentType.Local
        };
  };

  const [localBlock, setLocalBlock] = useState<IBlock>(() => getInitialState());
  const [showGameTable, setShowGameTable] = useState<boolean>(
    !localBlock.gameId
  );
  const [showGameAdded, setShowGameAdded] = useState<boolean>(false);
  const [showZoneTable, setShowZoneTable] = useState<boolean>(false);
  const [currentGame, setCurrentGame] = useState<IGame>();
  const [currentZone, setCurrentZone] = useState<IZoneVersion>();
  const [zones, setZones] = useState<IZoneVersion[]>();
  const [allGames, setAllGames] = useState<IGame[]>();
  const [zoneVersion, setZoneVersion] = useState<IZoneVersion | undefined>(
    undefined
  );
  const [isEntityTypeSelectorOpen, setIsEntityTypeSelectorOpen] =
    useState<boolean>(false);
  const [isActionTypeSelectorOpen, setIsActionTypeSelectorOpen] =
    useState<boolean>(false);

  const columns = React.useMemo(() => gameTableColumns, []);

  const { data: game, isLoading: isGameLoading } = useReqGetGuide(
    localBlock.gameId
  );

  useEffect(() => {
    if (!!localBlock.zoneId) {
      (async () => {
        const { data: zoneVersion, error } =
          await zoneOperations.getLiveVersionAsync(localBlock.zoneId!);
        if (error) {
          toast.error('Failed to get linked Zone. It may have been deleted.');
          return;
        }
        setZoneVersion(zoneVersion);
      })();
    }
  }, [localBlock.zoneId]);

  //NOTE: The useeffect hook here is used to check the current gameId for a guidesItem block,
  // if it exist, we make use of the Id to fetch the corresponding game from the database and
  //set the currentGame state to be the game retrieved. The useEffect runs on opening the edit Modal.
  useEffect(() => {
    if (game) {
      setCurrentGame(game);
    }
    if (zoneVersion) {
      setCurrentZone(zoneVersion);
      setShowGameTable(false);
    }
  }, [game, zoneVersion]);

  const onChangeSearchValue = async (query: string) => {
    if (!query || query.length < 2) {
      return;
    }

    const request: ISearchRequest = {
      query: query ?? '',
      order: Order.Relevance,
      entityFilter: Filter.Game,
      location: {
        latitude: 51,
        longitude: -0.2
      },
      take: 10
    };

    const { data: rankedResults, error } = await searchOperations.search(
      request
    );

    if (!rankedResults || error) {
      toast.error('No guides found');
      return;
    }

    const games = rankedResults.map((rr) =>
      rankedResultItemConverter.convertToGuide(rr)
    );
    setAllGames(games);
  };

  const isCtaBanner = localBlock.type === BlockType.CtaBanner;

  const isSpotsBlock =
    localBlock.type === BlockType.GuideSpots ||
    localBlock.type === BlockType.ZoneSpots;

  const isMostLikedBlock = localBlock.type === BlockType.MostLiked;

  const isTrendingPopularBlock =
    localBlock.type === BlockType.Trending ||
    localBlock.type === BlockType.Popular;

  const isCollectionRelatedBlock =
    localBlock.type === BlockType.Following ||
    localBlock.type === BlockType.CollectedNearMe;

  const isCollectionRelatedBlockSaveValid =
    !isCollectionRelatedBlock ||
    (localBlock.actionType === ActionType.Saved
      ? localBlock.entityType === BlockEntityType.Guide
      : localBlock.entityType !== BlockEntityType.Guide);

  const isCollectionRelatedBlockEntityTypeValid =
    !isCollectionRelatedBlock || localBlock.entityType !== BlockEntityType.Spot;

  const isSuggestedBlock =
    localBlock.type === BlockType.SuggestedEvents ||
    localBlock.type === BlockType.SuggestedListings ||
    localBlock.type === BlockType.SuggestedGuides;

  const shouldShowDisplayOptions =
    isBlockPage ||
    localBlock.type === BlockType.NewGuides ||
    localBlock.type === BlockType.TrendingGuides ||
    localBlock.type === BlockType.TopGuides ||
    localBlock.type === BlockType.Following ||
    localBlock.type === BlockType.CollectedNearMe ||
    localBlock.type === BlockType.Popular ||
    localBlock.type === BlockType.Trending ||
    localBlock.type === BlockType.MostLiked ||
    localBlock.type === BlockType.MyDownloadedGuides ||
    localBlock.type === BlockType.MyRecentGuides ||
    localBlock.type === BlockType.QuickAccess ||
    localBlock.type === BlockType.ViewByCategory ||
    localBlock.type === BlockType.FilterAndEventSearchWidgets ||
    localBlock.type === BlockType.CtaBanner ||
    localBlock.type === BlockType.Ads;

  const blockTypeFlyoutMenuItems = isBlockPage
    ? EnumHelper.getEnumValuesForFlyout(BlockType).filter((b) =>
        ManualBlockNames.includes(b.value)
      )
    : EnumHelper.getEnumValuesForFlyout(BlockType).filter(
        (b) => !OmittedBlockTypes.includes(b.value)
      );

  const actionTypeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(
    ActionType
  ).map((item) => ({
    ...item,
    label: item.label
  }));
  const entityTypeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(
    BlockEntityType
  ).filter((item) => item.label !== 'None');

  const isGuideSpotsBlock = localBlock.type === BlockType.GuideSpots;
  const isZoneSpotsBlock = localBlock.type === BlockType.ZoneSpots;
  const isTypeDefined = localBlock.type !== undefined;
  const isValidCountRange =
    !!localBlock.count && localBlock.count <= 20 && localBlock.count >= 0;

  const isCustomMadeBlock =
    localBlock.type === BlockType.FilterAndEventSearchWidgets ||
    localBlock.type === BlockType.ViewByCategory ||
    localBlock.type === BlockType.QuickAccess ||
    localBlock.type === BlockType.Ads ||
    localBlock.type === BlockType.MyRecentGuides;

  const isNonAdvertBlocks =
    localBlock.type === BlockType.FilterAndEventSearchWidgets ||
    localBlock.type === BlockType.ViewByCategory ||
    localBlock.type === BlockType.QuickAccess ||
    localBlock.type === BlockType.Ads;

  const isEditorialBlock =
    localBlock.heading !== undefined && localBlock.subHeading !== undefined;

  const isValidSpotsBlock =
    !isSpotsBlock ||
    (isBlockPage
      ? !!localBlock.gameId || !!localBlock.zoneId
      : isGuideSpotsBlock || isZoneSpotsBlock);

  const hasValidName =
    !!localBlock.name &&
    localBlock.name.length !== 0 &&
    localBlock.name.length <= blockNameMaxLength;

  function validateCtaBannerBlock() {
    const {
      title,
      description,
      type,
      linkText,
      textColour,
      cardColour,
      routeUrl,
      tagIds,
      mediaType,
      image,
      animationType
    } = localBlock.linkMetadata || {};

    return (
      !isCtaBanner ||
      (!!title &&
        title.length <= ctaBannerTitleLength &&
        !!description &&
        description.length <= ctaBannerDescriptionLength &&
        !!linkText &&
        linkText.length <= ctaBannerLinkTextLength &&
        !!textColour &&
        textColour.length <= ctaBannerColourHexCodeLength &&
        !!cardColour &&
        cardColour.length <= ctaBannerColourHexCodeLength &&
        ((type === CtaBannerType.App && !!routeUrl) ||
          (type === CtaBannerType.Search && !!tagIds)) &&
        (mediaType === CtaBannerMediaType.None ||
          !mediaType ||
          (mediaType === CtaBannerMediaType.Image && !!image) ||
          (mediaType === CtaBannerMediaType.Animation && !!animationType)))
    );
  }

  function validateEditorialBlock() {
    const { heading, subHeading, video } = localBlock;
    const { mediaType, image } = localBlock.linkMetadata || {};
    return (
      !isEditorialBlock ||
      (!!heading &&
        heading !== '' &&
        heading.length <= MAX_HEADING_LENGTH &&
        (!subHeading || subHeading.length <= MAX_SUBHEADING_LENGTH) &&
        (mediaType === EditorialBlockMediaType.None ||
          !mediaType ||
          (mediaType === EditorialBlockMediaType.Image && !!image) ||
          (mediaType === EditorialBlockMediaType.Video && !!video)))
    );
  }

  const isValidCtaBannerBlock = validateCtaBannerBlock();
  const isValid = isEditorialBlock
    ? validateEditorialBlock()
    : isTypeDefined &&
      (isCustomMadeBlock ||
        isCtaBanner ||
        isValidCountRange ||
        isScenario ||
        !shouldShowDisplayOptions) &&
      isValidSpotsBlock &&
      hasValidName &&
      isValidCtaBannerBlock;

  const setType = (type: number) => {
    const block = {
      ...localBlock,
      type: type,
      count: undefined
    };
    switch (type) {
      case BlockType.NewGuides:
      case BlockType.SuggestedGuides:
      case BlockType.TopGuides:
      case BlockType.TrendingGuides:
      case BlockType.MyDownloadedGuides:
        block.$type = guideBlockType;
        break;
      case BlockType.CtaBanner:
        block.$type = ctaBannerBlockType;
        break;
      case BlockType.GuideSpots:
      case BlockType.ZoneSpots:
        block.$type = spotBlockType;
        break;
      case BlockType.SuggestedListings:
        block.$type = listingBlockType;
        break;
      case BlockType.SuggestedEvents:
        block.$type = eventBlockType;
        break;
      case BlockType.VideosGroup:
        block.$type = videoBlockType;
        break;
      case BlockType.EntitiesGroup:
        block.$type = entityBlockType;
        break;
      case BlockType.CollectedNearMe:
      case BlockType.Popular:
      case BlockType.Trending:
      case BlockType.Following:
      case BlockType.MostLiked:
        block.$type = getBlockType(localBlock.entityType);
        break;
      default:
        block.$type = baseBlockType;
    }
    setLocalBlock(block);
  };

  function getBlockType(entityType?: BlockEntityType): string {
    switch (entityType) {
      case BlockEntityType.Listing:
        return listingBlockType;
      case BlockEntityType.Event:
        return eventBlockType;
      case BlockEntityType.Guide:
        return guideBlockType;
    }
    return listingBlockType;
  }

  const handleFlyoutOnChange = (e: FlyoutMenuItem) => {
    setType(BlockType[e.label]);
    isSpotsBlock && setShowGameTable(true);
    setShowZoneTable(false);
    setShowGameAdded(false);
    setCurrentGame(undefined);
    setCurrentZone(undefined);
  };

  const handleSelectGuideForGuideSpots = (gameId: string) => {
    const gameToAdd = allGames?.find((g) => g.id === gameId);

    setLocalBlock({
      ...localBlock,
      gameId: gameToAdd?.id
    });

    const gameAdded = allGames?.find((game) => {
      game.id = localBlock.gameId;
      return game;
    });

    setShowGameTable(false);
    setShowGameAdded(true);
    setCurrentGame(gameAdded);
  };

  const handleSelectGuideForZoneSpots = async (gameId: string) => {
    const gameToAdd = allGames?.find((g) => g.id === gameId);

    const { data, error } = await zoneOperations.listByGameAsync(gameId);
    if (!data || error) {
      toast.error('Failed to list zones for guide. Please try again');
      return;
    }

    setZones(data);
    setShowGameTable(false);
    setShowZoneTable(true);
    setCurrentGame(gameToAdd);
  };

  const handleSelectZoneForZoneSpots = async (zoneId: string) => {
    const zoneAdded = zones?.find((z) => z.versionable?.id === zoneId);

    setLocalBlock({
      ...localBlock,
      gameId: currentGame?.id,
      zoneId: zoneAdded?.versionable?.id
    });

    setCurrentZone(zoneAdded);
    setCurrentGame(undefined);
    setShowZoneTable(false);
    setShowGameAdded(true);
  };

  const handleRemoveItems = async () => {
    setLocalBlock({
      ...localBlock,
      gameId: undefined,
      zoneId: undefined
    });
    setShowGameAdded(false);
    setShowGameTable(true);
    setCurrentGame(undefined);
    setCurrentZone(undefined);

    updateItemsForGuideSpots && updateItemsForGuideSpots(guideItems, true);
  };

  const handleRemoveGame = () => {
    setCurrentGame(undefined);
    setShowZoneTable(false);
    setShowGameTable(true);
  };

  const submitForUpdating = () => {
    if (onClickUpdateSubmit) {
      onClickUpdateSubmit(localBlock);
    }
  };

  function getEntityTypeValue(value: string) {
    switch (value) {
      case 'Listing':
        return BlockEntityType.Listing;
      case 'Event':
        return BlockEntityType.Event;
      case 'Guide':
        return BlockEntityType.Guide;
      case 'Spot':
        return BlockEntityType.Spot;
    }
    return BlockEntityType.Listing;
  }

  function getActionTypeValue(value: string) {
    switch (value) {
      case 'Liked':
        return ActionType.Liked;
      case 'Visited':
        return ActionType.Visited;
      case 'Collected':
        return ActionType.Collected;
      case 'Saved':
        return ActionType.Saved;
    }
    return ActionType.Liked;
  }

  const localBlockToSubmit = {
    ...localBlock
  };

  if (isValid && localBlockToSubmit.linkMetadata) {
    localBlockToSubmit.linkMetadata.mediaType = undefined;
  }

  return (
    <div>
      <CardHeader
        title={
          title ||
          (isEditing
            ? 'Update Block Information'
            : 'Create A Discover Sub Page Block')
        }
        fontWeight="font-semibold"
      />

      <div className={`text-left mx-4 mt-5`}>
        {!isEditing && (
          <BlockTypeSelector
            flyoutMenuItems={blockTypeFlyoutMenuItems}
            block={localBlock}
            onChange={handleFlyoutOnChange}
          />
        )}
        <div className="flex flex-row">
          {isEditing && (
            <div className="flex flex-col">
              <span className="font-bold">{BlockType[localBlock.type!]}</span>
              <div>
                <span className="font-semibold">Block Id: </span>
                <span> {localBlock.id}</span>
              </div>
            </div>
          )}
          {localBlock.type !== undefined &&
            !isNonAdvertBlocks &&
            !isScenario &&
            !isCtaBanner &&
            shouldShowDisplayOptions && (
              <BlockEditorialToggle
                block={localBlock}
                onToggle={setLocalBlock}
              />
            )}
          {!isEditing && <div className="flex-grow" />}
        </div>
        {
          <div className="mt-2">
            {localBlock.type !== undefined && (
              <div>
                {!isEditing &&
                  !isBlockPage &&
                  (localBlock.type === BlockType.GuideSpots ||
                    localBlock.type === BlockType.ZoneSpots ||
                    localBlock.type === BlockType.EntitiesGroup ||
                    localBlock.type === BlockType.VideosGroup ||
                    isSuggestedBlock) && (
                    <ExistingBlockSelector
                      block={localBlock}
                      onBlockSelected={setLocalBlock}
                    />
                  )}
              </div>
            )}
          </div>
        }
        {localBlock.type !== undefined && shouldShowDisplayOptions && (
          <BlockDataForm
            block={localBlock}
            isScenario={isScenario}
            isBlockPage={isBlockPage}
            shouldUpdateSuggestedBlockNearMe={shouldUpdateSuggestedBlockNearMe}
            onModify={setLocalBlock}
          />
        )}
        {isCtaBanner && (
          <CtaBannerManage
            localBlock={localBlock}
            setLocalBlock={setLocalBlock}
            isEditorialBlock={isEditorialBlock}
          />
        )}

        {!isCtaBanner && !isScenario && (
          <div>
            {isBlockPage &&
              (localBlock.type === BlockType.ZoneSpots ||
                localBlock.type === BlockType.GuideSpots) &&
              showGameTable && (
                <GameTable
                  onChangeSearchValue={onChangeSearchValue}
                  onClickAddIcon={
                    localBlock.type === BlockType.GuideSpots
                      ? handleSelectGuideForGuideSpots
                      : handleSelectGuideForZoneSpots
                  }
                  tableColumns={columns as any}
                  games={allGames || []}
                  isLarge={false}
                />
              )}
            <BlockItemAdded
              game={currentGame}
              block={localBlock}
              zoneVersion={currentZone}
              showBlockItemsAdded={showGameAdded}
              isEditing={isEditing}
              isGameLoading={isGameLoading}
              isZoneLoading={!zoneVersion}
              removeItem={handleRemoveItems}
            />
            <div>
              {showZoneTable && (
                <div>
                  <div className="mt-1 overflow-y-scroll max-h-56">
                    <div className="flex flex-row px-6 pt-2 items-center justify-between">
                      <img
                        src={currentGame?.versions[0].icon}
                        alt="game icon"
                        width={'60px'}
                        height={'60px'}
                        className="rounded-md"
                      />
                      <div className="font-semibold text-lg px-4 text-center">
                        {currentGame?.versions[0].name}
                      </div>
                      <RiDeleteBin2Line
                        onClick={handleRemoveGame}
                        className="text-3xl cursor-pointer ml-8 hover:text-error1"
                      />
                    </div>
                    <div className="font-semibold text-lg px-4 mt-2">
                      Zones in
                    </div>
                    {zones &&
                      zones.map((z, i) => (
                        <div
                          key={i}
                          className="flex flex-row m-4 bg-theme5 h-auto p-4 items-center"
                        >
                          <img
                            src={z.icon}
                            alt="zone icon"
                            width={'80px'}
                            height={'80px'}
                            className="rounded-md"
                          />
                          <div className="mx-10 text-lg font-semibold">
                            {z.name}
                          </div>
                          <BiPlus
                            className="ml-auto mr-16 cursor-pointer hover:text-success"
                            size={40}
                            onClick={() =>
                              handleSelectZoneForZoneSpots(z.versionable?.id!)
                            }
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {isCollectionRelatedBlock && (
          <div>
            <div className="flex flex-col mb-4">
              <div className="font-inter text-lg font-bold">Action Type:</div>
              <FlyoutMenu
                activeKey={(actionTypeFlyoutMenuItems || []).findIndex(
                  (t) => getActionTypeValue(t.value) === localBlock.actionType
                )}
                isEqual={isEqual}
                size="sm"
                isMenuOpen={isActionTypeSelectorOpen}
                setIsMenuOpen={setIsActionTypeSelectorOpen}
                items={actionTypeFlyoutMenuItems}
                onChange={(e) =>
                  setLocalBlock({
                    ...localBlock,
                    actionType: getActionTypeValue(e.value)
                  })
                }
              />
            </div>
          </div>
        )}

        {(isCollectionRelatedBlock ||
          isMostLikedBlock ||
          isTrendingPopularBlock) && (
          <div>
            <div className="flex flex-col mb-4">
              <div className="font-inter text-lg font-bold">Entity Type:</div>
              <FlyoutMenu
                activeKey={(entityTypeFlyoutMenuItems || []).findIndex(
                  (t) => getEntityTypeValue(t.value) === localBlock.entityType
                )}
                isEqual={isEqual}
                size="sm"
                isMenuOpen={isEntityTypeSelectorOpen}
                setIsMenuOpen={setIsEntityTypeSelectorOpen}
                items={entityTypeFlyoutMenuItems}
                onChange={(e) =>
                  setLocalBlock({
                    ...localBlock,
                    entityType: getEntityTypeValue(e.value)
                  })
                }
              />
            </div>
            {!isCollectionRelatedBlockSaveValid && (
              <span className="text-error1">
                You can't Save a Listing/Event and you can't Visit/Like/Collect
                a Guide
              </span>
            )}
            {!isCollectionRelatedBlockEntityTypeValid && (
              <span className="text-error1">
                You can't select "Spot" when the BlockType is CollectedNearMe
              </span>
            )}
          </div>
        )}
        {isEditorialBlock && (
          <EditorialBlock
            localBlock={localBlock}
            setLocalBlock={setLocalBlock}
            isScenario={isScenario}
            isCtaBannerBlock={isCtaBanner}
          />
        )}
        {localBlock.type !== undefined &&
          (isCtaBanner || isScenario || isEditorialBlock) && (
            <div className="mt-2">
              <span className="font-semibold">{'Link Text:'}</span>
              <Input
                value={localBlock.linkMetadata?.linkText}
                onChange={(e) => {
                  setLocalBlock({
                    ...localBlock,
                    linkMetadata: {
                      ...localBlock.linkMetadata!,
                      linkText: e.currentTarget.value
                    }
                  });
                }}
                maxCharacterCount={ctaBannerLinkTextLength}
              />
            </div>
          )}

        {/* NOTE: Hiding this component until we're ready to properly implement the feature */}
        {/* {localBlock.type !== undefined && !isNonAdvertBlocks && !isScenario && (
          <div className="flex flex-col mb-4">
            <div className="font-inter text-lg font-bold">Advertisements</div>
            <div className="font-inter text-sm mt-2 font-bold">
              Free Frequency
              <span className="font-inter text-sm font-normal ml-1">
                (every 'x' card)
              </span>
            </div>
            <div>
              <Input
                className="w-36"
                value={localBlock.freeFrequency?.toString() || ''}
                onChange={(e) => {
                  setLocalBlock({
                    ...localBlock,
                    freeFrequency: parseInt(e.currentTarget.value, 10) || 0
                  });
                }}
              />
            </div>
          </div>
        )} */}

        {isEditing ? (
          <div>
            <div className="flex flex-row mt-4 justify-evenly">
              <Button
                onClick={submitForUpdating}
                disabled={
                  !isValid ||
                  !isCollectionRelatedBlockSaveValid ||
                  !isCollectionRelatedBlockEntityTypeValid
                }
              >
                {'Submit'}
              </Button>
              <Button onClick={onClickCloseUpdateModal} skin="secondary">
                <MdClose className="mr-1 mt-1" />
                <div>Cancel</div>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row mt-6 justify-evenly">
            <Button
              onClick={() => {
                onCreateDiscoverBlock &&
                  onCreateDiscoverBlock(localBlockToSubmit);
                setIsModalOpen(false);
              }}
              disabled={!isValid}
            >
              Add
            </Button>

            <Button onClick={() => setIsModalOpen(false)} skin="secondary">
              <MdClose className="mr-1 mt-1" />
              <div>Cancel</div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlockManagementModal;
