import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { FlyoutMenu, FlyoutMenuItem } from '@bindystreet/bindystreet.kit.react';
import { isEqual } from 'lodash';
import { ICategory } from 'Colugo/interfaces/common';
import React, { useState } from 'react';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';

type Props = {
  block: IBlock;
  onChange?: (block: IBlock) => void;
};

function BlockCategorySelector(props: Props) {
  // Props
  const { block, onChange } = props;

  // State
  const [isCategorySelectorOpen, setIsCategorySelectorOpen] = useState(false);

  const { data: categories } = useReqListCategories();

  const categoryFlyoutMenuItems: FlyoutMenuItem[] = categories
    ? categories.map((val) => {
        return { label: val.name, value: val };
      })
    : [];
  categoryFlyoutMenuItems.unshift({
    label: 'No item selected',
    value: undefined
  });

  return (
    <div className="mt-2">
      <span className="font-semibold">{'Category:'}</span>
      <FlyoutMenu
        activeLabel={block.category?.name}
        isEqual={isEqual}
        size="sm"
        isMenuOpen={isCategorySelectorOpen}
        setIsMenuOpen={setIsCategorySelectorOpen}
        items={categoryFlyoutMenuItems}
        onChange={(e) =>
          onChange?.({
            ...block!,
            category: e.value as ICategory
          })
        }
      />
    </div>
  );
}

export default BlockCategorySelector;
