import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { ITab, SurveyTabList } from './SurveyTabList';
import TabComponent from './TabComponent';
import SurveySummary from './SurveySummary';
import SurveySubmissions from './SurveySubmissions';
import BugEntries from './BugEntries';
import SuggestionEntries from './SuggestionEntries';

function SurveyContainer() {
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const currentTabName = SurveyTabList().find((tab) =>
    location.pathname.includes(tab.path)
  )?.name;

  const tabs = SurveyTabList();
  function handleOnClickTab(tab: ITab) {
    history.push(`${url}${tab.path}`);
  }

  return (
    <div className="h-full w-full justify-start flex p-12 overflow-y-auto overflow-x-hidden bg-white">
      <div className="flex-col w-full items-start justify-start">
        <div className="font-inter text-3xl font-bold flex justify-start">
          App Feedback
        </div>
        <div className="flex flex-row mt-4 w-full">
          {tabs.map((tab, i) => (
            <TabComponent
              key={i}
              index={i}
              tab={tab}
              activeTabName={currentTabName!}
              tabName={tab.name}
              onClickTab={() => handleOnClickTab(tab)}
            />
          ))}
        </div>

        <div
          className="bg-white flex flex-col items-start w-full border-t-2 border-surfaceOutline"
          style={{ marginTop: -2 }}
        >
          <Switch>
            <Route path={`${url}/summary`} component={SurveySummary} />
            <Route path={`${url}/submissions`} component={SurveySubmissions} />
            <Route path={`${url}/bug-entries`} component={BugEntries} />
            <Route
              path={`${url}/suggestions-entries`}
              component={SuggestionEntries}
            />
            <Redirect to="/survey/summary" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default SurveyContainer;
