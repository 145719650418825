import {
  ErrorPage,
  FlyoutMenu,
  FlyoutMenuItem,
  Input,
  Spinner
} from '@bindystreet/bindystreet.kit.react';
import { CardSize, Layout } from 'Colugo/interfaces/lobby/discover/enums';
import { useReqListTags } from 'Colugo/operations/tags';
import { EnumHelper } from 'Colugo/utility/helpers';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';
import ImageCropperInput from 'storybook/ImageCropperInput';
import { imageUploadOnDrop } from 'utility/general/ImageUploadOnDrop';
import TagSelector from './TagSelector';
import {
  CtaBannerMediaType,
  AnimationType,
  CtaBannerType,
  ILinkMetadata
} from 'Colugo/interfaces/lobby/discover/blocks/IBlock';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import {
  ctaBannerDescriptionLength,
  ctaBannerTitleLength
} from '../blockManagementModal/BlockManagementModal';

const mediaTypeFlyoutMenuItems =
  EnumHelper.getEnumValuesForFlyout(CtaBannerMediaType);
const animationTypesFlyoutMenuItems =
  EnumHelper.getEnumValuesForFlyout(AnimationType);

const ctaBannerFlyoutMenuItems =
  EnumHelper.getEnumValuesForFlyout(CtaBannerType);

const yellow: string = '#F8A549';
const navy: string = '#4B50A1';

type Props = {
  localBlock: IBlock;
  setLocalBlock: (localBlock: IBlock) => void;
  isEditorialBlock: boolean;
};

function CtaBannerManage(props: Props) {
  const { localBlock, setLocalBlock, isEditorialBlock } = props;
  const [activeBannerTypeKey, setActiveBannerTypeKey] = useState<number>(
    localBlock.linkMetadata?.type! || 0
  );
  const [activeAnimationTypeKey, setActiveAnimationTypeKey] = useState<number>(
    localBlock.linkMetadata?.animationType! || 0
  );
  const [activeMediaTypeKey, setActiveMediaTypeKey] = useState<number>(
    localBlock.linkMetadata?.image
      ? CtaBannerMediaType.Image
      : localBlock.linkMetadata?.animationType
      ? CtaBannerMediaType.Animation
      : 0
  );
  const [isBannerTypeMenuOpen, setIsBannerTypeMenuOpen] = useState(false);
  const [isMediaTypeMenuOpen, setIsMediaTypeMenuOpen] = useState(false);
  const [isColourTypeMenuOpen, setIsColourTypeMenuOpen] = useState(false);
  const [isAnimationTypeMenuOpen, setIsAnimationTypeMenuOpen] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isCroppingImage, setIsCroppingImage] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const onDropImageAsync = imageUploadOnDrop(
    addBannerImage,
    setIsLoadingImage,
    setProgress
  );

  const colourOptions: FlyoutMenuItem[] = [
    { label: 'Yellow', value: yellow },
    { label: 'Navy', value: navy }
  ];
  async function handleUploadIconAsync(image: File) {
    setIsCroppingImage(false);
    setIsLoadingImage(true);
    await onDropImageAsync([image]);
    setIsLoadingImage(false);
  }

  const {
    data: tags,
    isLoading: isTagsLoading,
    isError: isTagError
  } = useReqListTags();

  const linkMetadata: ILinkMetadata = localBlock.linkMetadata || {
    type: CtaBannerType.None,
    textColour: '#FFFFFF'
  };

  function handleBannerTypeFlyoutOnChange(e: FlyoutMenuItem) {
    setActiveBannerTypeKey(e.key!);
    const updatedBlock: IBlock = {
      ...localBlock,
      layout: Layout.Horizontal,
      count: 2,
      linkMetadata: {
        ...linkMetadata,
        type: e.key!,
        routeUrl:
          linkMetadata.type === CtaBannerType.App ? linkMetadata.routeUrl : '',
        tagIds:
          linkMetadata.type === CtaBannerType.Search ? linkMetadata.tagIds : []
      }
    };
    setLocalBlock(updatedBlock);
  }

  function handleAnimationTypeFlyoutOnChange(e: FlyoutMenuItem) {
    setActiveAnimationTypeKey(e.key!);
    const updatedBlock: IBlock = {
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        animationType: e.key!
      }
    };
    setLocalBlock(updatedBlock);
  }

  const linkTags = linkMetadata.tagIds?.map((tagId) => {
    const tag = tags?.find((t) => t.id === tagId);
    return tag!;
  });

  function handleAddTag(tagId: string) {
    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        tagIds: [...(linkMetadata.tagIds || []), tagId]
      }
    });
  }

  function handleRemoveTag(tagId: string) {
    const tagIds = linkMetadata.tagIds?.filter((t) => t !== tagId);

    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        tagIds: tagIds
      }
    });
  }

  function handleMediaTypeFlyoutChange(e: FlyoutMenuItem) {
    setActiveMediaTypeKey(e.key!);
    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        mediaType: e.key,
        image:
          e.key === CtaBannerMediaType.None || CtaBannerMediaType.Animation
            ? undefined
            : linkMetadata.image,
        animationType:
          e.key === CtaBannerMediaType.None || CtaBannerMediaType.Image
            ? undefined
            : linkMetadata.animationType
      }
    });
  }

  function handleColourTypeFlyoutChange(e: FlyoutMenuItem) {
    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        cardColour: e.value,
        textColour: e.value === navy ? yellow : navy
      }
    });
  }

  function addBannerImage(imageUrl: string) {
    if (!imageUrl) {
      toast.error('No image was selected, Please try again');
      return;
    }

    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        image: imageUrl
      }
    });
  }

  function deleteBannerImage() {
    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        image: ''
      }
    });
  }

  if (isTagsLoading) {
    return <Spinner />;
  }

  if (isTagError) {
    return (
      <ErrorPage>
        <span>{'Unable to load tags from server.'}</span>
      </ErrorPage>
    );
  }

  function CtaBannerTypeOptions(ctaBannerType: CtaBannerType) {
    switch (ctaBannerType) {
      case CtaBannerType.App:
        return (
          <div className="mt-2">
            <span className="font-semibold">{'Banner Link Route Url: '}</span>
            <span className="font-normal text-sm">
              {
                '(This a url for deep linking into specific screens or item in the App, Get this url from the App team)'
              }
            </span>
            <Input
              value={linkMetadata.routeUrl}
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  linkMetadata: {
                    ...linkMetadata,
                    routeUrl: e.currentTarget.value
                  }
                });
              }}
              maxCharacterCount={60}
            />
          </div>
        );
      case CtaBannerType.Search:
        return (
          <div className="mt-2">
            <span className="font-semibold">{'Add Tags: '}</span>
            <span className="font-normal text-sm">
              {'(Only for search CTA Banners)'}
            </span>
            <div className="mt-1">
              <TagSelector
                tags={tags || []}
                selectedTags={linkTags || []}
                onConfirmNewTag={handleAddTag}
                onClickTagDeleteIcon={handleRemoveTag}
              />
            </div>
          </div>
        );
    }
  }

  const smallImageResolution = {
    width: 144,
    height: 175
  };

  const largeImageResolution = {
    width: 404,
    height: 279
  };

  function CtaBannerMediaTypeOptions(activeMediaTypeKey: CtaBannerMediaType) {
    switch (activeMediaTypeKey) {
      case 1:
        return (
          <div className="mt-2 flex justify-start items-start">
            {(activeMediaTypeKey === CtaBannerMediaType.Image ||
              linkMetadata.image) && (
              <div className="mt-2">
                <span className="font-semibold">{'Upload Image'}</span>
                <div className="mt-1">
                  <ImageCropperInput
                    onCropComplete={(icon) => {
                      handleUploadIconAsync(icon);
                    }}
                    imageUrl={linkMetadata.image}
                    progress={progress}
                    isCroppingImage={isCroppingImage}
                    setIsCroppingImage={setIsCroppingImage}
                    isLoading={isLoadingImage}
                    deleteImage={() => deleteBannerImage()}
                    isMandatory={true}
                    resolution={
                      localBlock.cardSize === CardSize.Small
                        ? smallImageResolution
                        : largeImageResolution
                    }
                    customPlaceholderSize={1}
                    customHeightPx={
                      localBlock.cardSize === CardSize.Small
                        ? `${smallImageResolution.height.toString()}px`
                        : `${largeImageResolution.height.toString()}px`
                    }
                    customWidthPx={
                      localBlock.cardSize === CardSize.Small
                        ? `${smallImageResolution.width.toString()}px`
                        : `${largeImageResolution.width.toString()}px`
                    }
                  />
                </div>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="mt-2">
            {activeMediaTypeKey === CtaBannerMediaType.Animation && (
              <div>
                <span className="font-semibold">{'Animation Types'}</span>
                <div className="font-normal italic">
                  {'Pick any one of the animation types below'}
                </div>
                <div className="mt-1">
                  <FlyoutMenu
                    isEqual={isEqual}
                    size="sm"
                    activeKey={activeAnimationTypeKey}
                    items={animationTypesFlyoutMenuItems}
                    onChange={handleAnimationTypeFlyoutOnChange}
                    isMenuOpen={isAnimationTypeMenuOpen}
                    setIsMenuOpen={setIsAnimationTypeMenuOpen}
                  />
                </div>
              </div>
            )}
          </div>
        );
    }
  }

  return (
    <div>
      {!isEditorialBlock && (
        <div
          className={`flex flex-col py-1 relative ${
            isAnimationTypeMenuOpen ? 'mb-48' : 'mb-24'
          }`}
        >
          <div className="w-full mt-1 flex items-center justify-between">
            <div className="w-full">
              <span className="font-semibold">{'Banner Type:'}</span>
              <div className="mt-1">
                <FlyoutMenu
                  isEqual={isEqual}
                  size="sm"
                  activeKey={activeBannerTypeKey}
                  items={ctaBannerFlyoutMenuItems}
                  onChange={handleBannerTypeFlyoutOnChange}
                  isMenuOpen={isBannerTypeMenuOpen}
                  setIsMenuOpen={setIsBannerTypeMenuOpen}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center pr-3 relative ">
            <div className="w-2/5 mt-4 cursor-pointer">
              <span className="font-semibold">{'Banner Card Colour: '}</span>
              <FlyoutMenu
                isEqual={isEqual}
                size="sm"
                activeKey={linkMetadata.textColour === navy ? 0 : 1}
                items={colourOptions}
                onChange={handleColourTypeFlyoutChange}
                isMenuOpen={isColourTypeMenuOpen}
                setIsMenuOpen={setIsColourTypeMenuOpen}
              />
            </div>
          </div>
          <div className="mt-2">
            <span className="font-semibold">{'Banner Title:'}</span>
            <Input
              value={linkMetadata.title}
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  linkMetadata: {
                    ...linkMetadata,
                    title: e.currentTarget.value
                  }
                });
              }}
              maxCharacterCount={ctaBannerTitleLength}
            />
          </div>
          <div className="mt-2">
            <span className="font-semibold">{'Banner Description:'}</span>
            <Input
              value={linkMetadata.description}
              multiline
              size="xl"
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  linkMetadata: {
                    ...linkMetadata,
                    description: e.currentTarget.value
                  }
                });
              }}
              maxCharacterCount={ctaBannerDescriptionLength}
            />
          </div>

          {CtaBannerTypeOptions(linkMetadata.type || CtaBannerType.None)}

          <div className="mt-2 w-3/4">
            <span className="font-semibold">{'Media Type (Optional)'}</span>
            <div className="font-normal italic">
              {'You can either have an Image or Animation'}
            </div>
            <div className="mt-1">
              <FlyoutMenu
                isEqual={isEqual}
                size="sm"
                activeKey={activeMediaTypeKey}
                items={mediaTypeFlyoutMenuItems}
                onChange={handleMediaTypeFlyoutChange}
                isMenuOpen={isMediaTypeMenuOpen}
                setIsMenuOpen={setIsMediaTypeMenuOpen}
              />
            </div>
          </div>
          {CtaBannerMediaTypeOptions(activeMediaTypeKey)}
        </div>
      )}
    </div>
  );
}

export default CtaBannerManage;
