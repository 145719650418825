import { FlyoutMenu } from '@bindystreet/bindystreet.kit.react';
import { BlockType, CardSize } from 'Colugo/interfaces/lobby/discover/enums';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { EnumHelper } from 'Colugo/utility/helpers';
import { CtaBannerCardSize } from 'Colugo/interfaces/lobby/discover/enums/CardSize';

type Props = {
  block: IBlock;
  onChange?: (block: IBlock) => void;
};

function BlockTypeSelector(props: Props) {
  // Props
  const { block, onChange } = props;

  // State
  const [isCardSizeSelectorOpen, setIsCardSizeSelectorOpen] =
    useState<boolean>(false);

  const isCtaBanner = block.type === BlockType.CtaBanner;
  const cardSizeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(
    isCtaBanner ? CtaBannerCardSize : CardSize
  );

  return (
    <div className="mt-2">
      <span className="font-semibold">{'Card size:'}</span>
      <FlyoutMenu
        activeLabel={block.cardSize}
        isEqual={isEqual}
        size="sm"
        isMenuOpen={isCardSizeSelectorOpen}
        setIsMenuOpen={setIsCardSizeSelectorOpen}
        items={cardSizeFlyoutMenuItems}
        onChange={(e) => onChange?.({ ...block, cardSize: e.value })}
      />
    </div>
  );
}

export default BlockTypeSelector;
