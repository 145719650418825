import { ITab } from './SurveyTabList';

type Props = {
  activeTabName: string;
  tabName: string;
  index: number;
  tab: ITab;
  onClickTab: () => void;
};

function TabComponent(props: Props) {
  const { tab, tabName, activeTabName, onClickTab } = props;

  return (
    <div className="flex flex-row justify-start h-auto" onClick={onClickTab}>
      <div
        className={`flex flex-row items-start py-1 cursor-pointer border-b-4
          ${tabName === activeTabName ? 'border-primary' : 'border-none'}`}
      >
        <div
          className={`${
            tabName === activeTabName
              ? 'heading-semibold-l-onSurface'
              : 'heading-semibold-l-onSurfaceVariant'
          } font-inter text-xl font-semibold mx-8`}
        >
          {tab.name}
        </div>
      </div>
    </div>
  );
}

export default TabComponent;
