import { IUserReport } from 'Colugo/interfaces/moderation/IUserReport';
import ReportSource from 'Colugo/interfaces/moderation/enums/ReportSource';
import ResolutionStatus from 'Colugo/interfaces/moderation/enums/ResolutionStatus';
import IMoment from 'Colugo/interfaces/social/IMoment';
import { UserOperations } from 'Colugo/operations/identity';
import UserReportOperations, {
  useReqListUserReportsAsync
} from 'Colugo/operations/moderation/UserReportOperations';
import MomentOperations from 'Colugo/operations/social/post/MomentOperations';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { skinType } from 'component/utility/ConfirmDeletePopup';
import { useEffect, useState } from 'react';
import { ImHammer2 } from 'react-icons/im';
import { IoMdCheckmark } from 'react-icons/io';
import { IoPause } from 'react-icons/io5';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import UserReportTable from '../UserReportsTable';

const userOperations = container.resolve(UserOperations);
const momentOperations = container.resolve(MomentOperations);
const userReportOperations = container.resolve(UserReportOperations);

type Props = {
  reportType: ReportSource;
  onConfirm?: (state: {
    title: string;
    skin: skinType;
    onSubmit: () => void;
  }) => void;
};

const UserReportsTableColumns = [
  {
    Header: 'Reported User',
    accessor: 'reportedUser.username'
  },
  {
    Header: 'Reason',
    accessor: 'reportReason'
  },
  {
    Header: 'Date Submitted',
    accessor: 'createdAt'
  },
  {
    Header: 'delete',
    accessor: 'id',
    width: 1
  }
];

function UserReportsTab({ reportType, onConfirm }: Props) {
  const [selectedReport, setSelectedReport] = useState<
    IUserReport | undefined
  >();

  const { data: userReports, mutate: updateUserReports } =
    useReqListUserReportsAsync(reportType);

  useEffect(() => {
    setSelectedReport(undefined);
  }, [reportType]);

  async function updateResolutionStatusAsync(
    report: IUserReport,
    resolutionStatus: ResolutionStatus
  ) {
    return await userReportOperations.updateResolutionStatusAsync(
      report.id!,
      (report.resolutionStatus || ResolutionStatus.None) | resolutionStatus
    );
  }

  async function onResolveReportAsync(
    report: IUserReport | undefined,
    status: ResolutionStatus = ResolutionStatus.Resolved
  ) {
    if (!report) {
      return;
    }

    const { error } = await updateResolutionStatusAsync(
      report,
      (report.resolutionStatus || ResolutionStatus.None) | status
    );
    if (error) {
      toast.error('Unable to resolve report. Please try again.');
      return;
    }
    toast.success('Report has been resolved.');
    updateUserReports(
      userReports?.filter((r) => r.id !== report.id),
      true
    );
  }

  async function onSuspendUserAsync(report: IUserReport | undefined) {
    if (!report) {
      return;
    }

    const { error } = await userOperations.suspendAsync(
      report.reportedUser?.id!
    );
    if (error) {
      toast.error('Unable to suspend user. Please try again');
      return;
    }
    toast.success(`User: @${report.reportedUser?.username} has been suspended`);
    await updateResolutionStatusAsync(report, ResolutionStatus.UserSuspended);
    updateUserReports(
      userReports?.filter((r) => r.id !== report.id),
      true
    );
  }

  async function onBanUserAsync(report: IUserReport | undefined) {
    if (!report) {
      return;
    }

    const { error } = await userOperations.suspendAsync(
      report.reportedUser?.id!
    );
    if (error) {
      toast.error('Unable to ban user. Please try again');
      return;
    }
    toast.success(`User: @${report.reportedUser?.username} has been banned`);
    await updateResolutionStatusAsync(report, ResolutionStatus.UserBanned);
    updateUserReports(
      userReports?.filter((r) => r.id !== report.id),
      true
    );
  }

  async function onDeleteContentAsync(report: IUserReport | undefined) {
    if (!report || !report.entity) {
      return;
    }

    let response: requestResponse<unknown> | undefined;
    let type: string = '';
    let status: ResolutionStatus = ResolutionStatus.None;
    switch (report.reportSource) {
      case ReportSource.None:
      case ReportSource.User:
        return;
      case ReportSource.Moment:
        response = momentOperations.deleteAsync(report.entity.id!);
        type = 'Moment';
        status = ResolutionStatus.MomentDeleted;
        break;
    }
    if (!response) {
      return;
    }

    const { error } = await response;
    if (error) {
      toast.error(`Failed to delete ${type}, please try again.`);
      return;
    }
    await updateResolutionStatusAsync(report, status);
    toast.success(`${type} successfully deleted`);
    updateUserReports(
      userReports?.filter((r) => r.id !== report.id),
      true
    );
  }

  function resolveUserReport(userReportId?: string) {
    onConfirm?.({
      title: 'Confirm Resolve User Report?',
      skin: 'resolveUserReport',
      onSubmit: () =>
        onResolveReportAsync(
          userReports?.find((ur) => ur.id === userReportId) ?? selectedReport
        )
    });
  }

  function deleteReportContent() {
    onConfirm?.({
      title: `Confirm Delete Moment?`,
      skin: 'deleteMoment',
      onSubmit: () => onDeleteContentAsync(selectedReport)
    });
  }

  function suspendUser() {
    onConfirm?.({
      title: 'Confirm Suspend User?',
      skin: 'suspendUser',
      onSubmit: () => onSuspendUserAsync(selectedReport)
    });
  }

  function banUser() {
    onConfirm?.({
      title: 'Confirm Ban User?',
      skin: 'banUser',
      onSubmit: () => onBanUserAsync(selectedReport)
    });
  }

  function formatDate(date: Date) {
    const time = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    const dateString = date.toLocaleDateString([], {
      day: '2-digit',
      month: 'long'
    });
    const year = date.toLocaleDateString([], {
      year: 'numeric'
    });
    return `${dateString}, ${year} at ${time}`;
  }

  function renderData(title: string, data?: string) {
    return (
      <div className="w-full gap-4 flex flex-col">
        <div className="text-left heading-semibold-sm-onSurface">{title}</div>
        <div className="w-full border border-outline bg-surface rounded-md px-4 py-2">
          <div className="flex w-full">{data}</div>
        </div>
      </div>
    );
  }

  function renderContent() {
    if (reportType === ReportSource.User || reportType === ReportSource.None) {
      return <></>;
    }

    function renderMoment() {
      const moment = selectedReport?.entity as IMoment;

      return (
        <div className="w-full flex flex-row gap-4 pt-2">
          <div className="flex flex-col gap-4 pt-2 w-40">
            {moment.attachments?.[0]?.url && (
              <>
                <div className="text-left heading-semibold-sm-onSurface">
                  Image
                </div>
                <img
                  className="flex-shrink-0 w-40 h-40"
                  src={moment.attachments[0].url}
                  alt={'Moment'}
                />
              </>
            )}
          </div>
          <div className="flex flex-col flex-grow gap-4 pt-2">
            {moment.caption && (
              <>
                <div className="text-left heading-semibold-sm-onSurface">
                  Caption
                </div>
                <div className="h-full w-full border border-outline bg-surface rounded-md px-4 py-2">
                  <div className="text-justify">{moment.caption}</div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="w-full flex flex-col">
        <div className="text-left heading-semibold-m-onSurface">Content</div>
        {reportType === ReportSource.Moment && renderMoment()}
      </div>
    );
  }

  function renderActions() {
    return (
      <div className={'w-full justify-between flex flex-row '}>
        <div
          className="flex flex-col flex-shrink-0 cursor-pointer items-center justify-center p-3 rounded-lg border border-green w-25 h-25 hover:bg-surfaceContainer"
          onClick={() => resolveUserReport()}
        >
          <IoMdCheckmark className={'text-4xl text-green'} />
          <div>Resolve</div>
        </div>
        {reportType === ReportSource.Moment && (
          <div
            className="flex flex-col flex-shrink-0 cursor-pointer items-center justify-center p-3 rounded-lg border border-purple w-25 h-25  hover:bg-surfaceContainer"
            onClick={deleteReportContent}
          >
            <RiDeleteBin2Line className={'text-4xl text-purple'} />
            <div>Delete Content</div>
          </div>
        )}
        <div
          className="flex flex-col flex-shrink-0 cursor-pointer items-center justify-center p-3 rounded-lg border border-yellow w-25 h-25  hover:bg-surfaceContainer"
          onClick={suspendUser}
        >
          <IoPause className={'text-4xl text-yellow'} />
          <div>Suspend</div>
        </div>
        <div
          className="flex flex-col flex-shrink-0 cursor-pointer items-center justify-center p-3 rounded-lg border border-red w-25 h-25  hover:bg-surfaceContainer"
          onClick={banUser}
        >
          <ImHammer2 className={'text-4xl text-red'} />
          <div>Ban</div>
        </div>
      </div>
    );
  }

  function renderUserReport() {
    if (!selectedReport) {
      return <></>;
    }

    return (
      <div className="w-full gap-4 px-12 py-8 flex flex-col">
        <div className="text-left heading-semibold-m-onSurface">
          Reported User Details
        </div>
        <div className="w-full flex flex-row gap-4">
          {renderData('Username', `@${selectedReport.reportedUser?.username}`)}
          {selectedReport.reportedUser?.displayName &&
            renderData(
              'Display Name',
              selectedReport.reportedUser?.displayName
            )}
        </div>
        {reportType === ReportSource.User && (
          <div className="w-full flex flex-row gap-4">
            {selectedReport.reportedUser?.bio &&
              renderData('Bio', selectedReport.reportedUser?.bio)}
            {selectedReport.reportedUser?.avatar && (
              <div className="w-full gap-4 flex flex-col">
                <div className="text-left heading-semibold-sm-onSurface">
                  Avatar
                </div>
                <div className="w-full border border-outline bg-surface rounded-md px-4 py-2">
                  <img
                    className="flex w-full"
                    src={selectedReport.reportedUser?.avatar}
                    alt={'User Avatar'}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="text-left heading-semibold-m-onSurface">
          Report Details
        </div>
        {renderData(
          'Submission Date',
          formatDate(new Date(selectedReport.createdAt!))
        )}
        {renderData('Report Reason', `${selectedReport.reportReason}`)}
        {renderContent()}
        {renderActions()}
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-row w-full h-full gap-10 py-4">
      <UserReportTable
        sourceType={reportType}
        tableColumns={UserReportsTableColumns as any}
        userReports={userReports || []}
        onResolveReport={resolveUserReport}
        onSelectReport={setSelectedReport}
      />
      <div className="mt-4 flex flex-1 flex-col justify-items-start overflow-y-auto">
        <div className="text-left align-text-bottom heading-semibold-sm-onSurface">
          Submission View
        </div>
        <div className="mt-4 bg-surfaceContainerLow rounded-lg flex flex-1">
          {renderUserReport()}
        </div>
      </div>
    </div>
  );
}

export default UserReportsTab;
