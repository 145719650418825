import AbstractOperations from 'Colugo/operations/AbstractOperations';
import { requestResponse } from 'Colugo/provider/HttpClient';

class MomentOperations extends AbstractOperations {
  protected basePath: string = 'social/moment';

  public async deleteAsync(momentId: string): requestResponse {
    return await this.executeDelete(momentId);
  }
}

export default MomentOperations;
