import {
  FlyoutMenu,
  FlyoutMenuItem,
  Input,
  Spinner
} from '@bindystreet/bindystreet.kit.react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import {
  CtaBannerType,
  EditorialBlockMediaType,
  ILinkMetadata
} from 'Colugo/interfaces/lobby/discover/blocks/IBlock';
import { IVideo } from 'Colugo/interfaces/video/IVideo';
import VideoOperations from 'Colugo/operations/video/VideoOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import SimpleImageUploader from 'component/utility/SimpleImageUploader';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { useSearchVideos } from 'utility/hooks/useLocalSearchVideos';
import VideoView from './VideoView';

const videoOperations = container.resolve(VideoOperations);

type Props = {
  localBlock: IBlock;
  setLocalBlock: (localBlock: IBlock) => void;
  isScenario: boolean;
  isCtaBannerBlock: boolean;
};

export const MAX_HEADING_LENGTH = 80;
export const MAX_SUBHEADING_LENGTH = 350;

const mediaTypeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(
  EditorialBlockMediaType
);

function EditorialBlock(props: Props) {
  const { localBlock, setLocalBlock, isScenario } = props;
  const [activeMediaTypeKey, setActiveMediaTypeKey] =
    useState<EditorialBlockMediaType>(
      localBlock.linkMetadata?.image
        ? EditorialBlockMediaType.Image
        : localBlock.video
        ? EditorialBlockMediaType.Video
        : EditorialBlockMediaType.None
    );
  const [isMediaTypeMenuOpen, setIsMediaTypeMenuOpen] = useState(false);
  const [isVideosLoading, setIsVideosLoading] = useState(false);
  const [videos, setVideos] = useState<IVideo[]>([]);
  const linkMetadata: ILinkMetadata = localBlock.linkMetadata || {
    type: CtaBannerType.None
  };

  const [query, setQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState<IVideo[]>(videos ?? []);
  const searchVideos = useSearchVideos(videos || [], setFilteredData);

  useEffect(() => {
    if (
      linkMetadata?.mediaType === EditorialBlockMediaType.Video ||
      localBlock.video
    ) {
      listVideosAsync();
    }
  }, [linkMetadata?.mediaType, localBlock.video]);

  async function listVideosAsync() {
    setIsVideosLoading(true);
    const { data, error } = await videoOperations.listAsync();
    if (error) {
      toast.error('Failed to get videos');
      setIsVideosLoading(false);
      return;
    }

    if (data) {
      setVideos(data);
    }
    setIsVideosLoading(false);
  }

  async function handleMediaTypeFlyoutChangeAsync(e: FlyoutMenuItem) {
    setActiveMediaTypeKey(e.key!);
    setLocalBlock({
      ...localBlock,
      video:
        e.key === EditorialBlockMediaType.None || EditorialBlockMediaType.Image
          ? undefined
          : localBlock.video,
      linkMetadata: {
        ...linkMetadata,
        mediaType: e.key,
        image:
          e.key === EditorialBlockMediaType.None ||
          EditorialBlockMediaType.Video
            ? undefined
            : linkMetadata.image
      }
    });
  }

  function addImage(imageUrl: string) {
    if (!imageUrl) {
      toast.error('No image was selected, Please try again');
      return;
    }

    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        mediaType: EditorialBlockMediaType.Image,
        image: imageUrl
      }
    });
  }

  function deleteImage() {
    setLocalBlock({
      ...localBlock,
      linkMetadata: {
        ...linkMetadata,
        image: undefined
      }
    });
  }

  function addVideo(video: IVideo) {
    setLocalBlock({
      ...localBlock,
      video: video
    });
    setFilteredData([]);
    setQuery('');
  }

  function deleteVideo() {
    setLocalBlock({
      ...localBlock,
      video: undefined,
      linkMetadata: {
        ...linkMetadata
      }
    });
  }

  function EditorialBlockMediaTypeOptions(
    activeMediaTypeKey: EditorialBlockMediaType
  ) {
    switch (activeMediaTypeKey) {
      case EditorialBlockMediaType.Image:
        return (
          <div className="mt-2 flex justify-start items-start">
            <div className="mt-2">
              <span className="font-semibold">{'Upload Image'}</span>
              <SimpleImageUploader
                imageUrl={linkMetadata.image}
                onimageUpload={(image) => addImage(image)}
                onClickDelete={deleteImage}
              />
            </div>
          </div>
        );
      case EditorialBlockMediaType.Video:
        return (
          <div className="mt-2">
            <div>
              {!localBlock.video && (
                <div>
                  {' '}
                  <span className="font-semibold">{'Video Search'}</span>
                  <div className="font-normal italic">
                    <Input
                      className="flex-shrink-0 mb-6 md:mb-3 flex-grow"
                      placeholder="Search Videos"
                      color="text-gray-400"
                      size="lg"
                      value={query || ''}
                      onChange={(e) => {
                        setQuery(e.currentTarget.value);
                        searchVideos(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              )}

              {isVideosLoading ? (
                <Spinner />
              ) : (
                <>
                  <div className="mt-1">
                    {filteredData && filteredData.length > 0 && (
                      <div className="font-semibold">Search Results</div>
                    )}
                    <VideoView videos={filteredData} addVideo={addVideo} />
                  </div>

                  <div>
                    {localBlock.video && !query && (
                      <div>
                        <div className="font-semibold">Selected Video</div>
                        <VideoView
                          videos={[localBlock.video]}
                          deleteVideo={deleteVideo}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        );
    }
  }

  return (
    <div>
      {!isScenario && (
        <div className="font-inter text-lg font-bold">Editorial Section</div>
      )}
      <div>
        {!isScenario && (
          <div className="mt-2">
            <span className="font-semibold">
              {
                'Topic (Note:Topic overrides block name in the app if provided): '
              }
            </span>
            <Input
              value={localBlock.linkMetadata?.topic}
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  linkMetadata: {
                    ...localBlock.linkMetadata!,
                    topic: e.currentTarget.value
                  }
                });
              }}
              maxCharacterCount={70}
            />
          </div>
        )}
        {!isScenario && (
          <div className="mt-2">
            <span className="font-semibold">{'Heading:'}</span>
            <Input
              value={localBlock.heading}
              multiline
              size="xl"
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  heading: e.currentTarget.value,
                  linkMetadata: {
                    ...localBlock.linkMetadata,
                    mediaType: activeMediaTypeKey
                  }
                });
              }}
              maxCharacterCount={MAX_HEADING_LENGTH}
              placeholder="Enter your heading"
            />
          </div>
        )}
        {!isScenario && (
          <div className="mt-2">
            <span className="font-semibold">{'Subheading:'}</span>
            <Input
              value={localBlock.subHeading}
              multiline
              size="xl"
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  subHeading: e.currentTarget.value,
                  linkMetadata: {
                    ...localBlock.linkMetadata,
                    mediaType: activeMediaTypeKey
                  }
                });
              }}
              maxCharacterCount={MAX_SUBHEADING_LENGTH}
              placeholder="Enter your short subheading"
            />
          </div>
        )}
        {!isScenario && (
          <div className="mt-2 w-3/4">
            <span className="font-semibold">{'Media Type'}</span>
            <div className="font-normal italic">
              {'You can either have an Image, Video or None'}
            </div>
            <div className="mt-1">
              <FlyoutMenu
                isEqual={isEqual}
                size="sm"
                activeKey={activeMediaTypeKey}
                items={mediaTypeFlyoutMenuItems}
                onChange={handleMediaTypeFlyoutChangeAsync}
                isMenuOpen={isMediaTypeMenuOpen}
                setIsMenuOpen={setIsMediaTypeMenuOpen}
              />
            </div>
          </div>
        )}
        {EditorialBlockMediaTypeOptions(activeMediaTypeKey)}
        {!isScenario && (
          <div className="mt-2">
            <span className="font-semibold">{'Link Url: '}</span>
            <Input
              value={localBlock.linkMetadata?.routeUrl}
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  linkMetadata: {
                    ...localBlock.linkMetadata!,
                    routeUrl: e.currentTarget.value
                  }
                });
              }}
              maxCharacterCount={60}
            />
          </div>
        )}{' '}
      </div>
    </div>
  );
}

export default EditorialBlock;
