import React, { useState } from 'react';
import { NestedSidebarMenu } from './DashboardSidebar';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { IoIosStats } from 'react-icons/io';
import { Link } from 'react-router-dom';

type Props = {
  nestedSidebarMenu: NestedSidebarMenu;
  isActiveLink: (newActiveLinkName: string) => boolean;
};

function CustomSideBarMenu(props: Props) {
  const { nestedSidebarMenu, isActiveLink } = props;
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState<boolean>(false);

  return (
    <div
      className={`w-full ${
        isSidebarMenuOpen ? 'h-auto mb-2' : 'h-0 mb-12'
      } flex flex-col items-start mt-4`}
    >
      <div
        className="h-9 p-2 w-full justify-between flex items-center text bg-surfaceContainerHighest"
        style={{ borderRadius: '8px 8px 0px 0px' }}
      >
        <div className="flex justify-center items-center">
          <IoIosStats fontSize="1.5em" className="ml-1" />
          <div className="ml-2 text-onSurface">
            {nestedSidebarMenu.headerText}
          </div>
        </div>
        {isSidebarMenuOpen ? (
          <BiChevronDown
            size={24}
            onClick={() => setIsSidebarMenuOpen(false)}
            className="cursor-pointer"
          />
        ) : (
          <BiChevronUp
            size={24}
            onClick={() => setIsSidebarMenuOpen(true)}
            className="cursor-pointer"
          />
        )}
      </div>
      {isSidebarMenuOpen && (
        <div
          className="bg-darkGreen w-full h-auto py-2 flex flex-col items-start"
          style={{ borderRadius: '0px 0px 8px 8px' }}
        >
          {nestedSidebarMenu.sideBarMenu.map((sm, i) => (
            <div key={i} className="h-auto flex w-full -mt-3">
              <Link
                key={sm.text}
                to={sm.link}
                className={`flex items-center mt-2 py-3 px-2 w-full`}
                style={{
                  backgroundColor: isActiveLink(sm.link)
                    ? 'rgba(14, 81, 75, 0.8)'
                    : '#081716'
                }}
              >
                <sm.icon fontSize="1.5em" className="ml-1" color="A0AEC0" />
                <span className="mx-4 text-white">{sm.text}</span>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomSideBarMenu;
