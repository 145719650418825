import { FlyoutMenu } from '@bindystreet/bindystreet.kit.react';
import { Layout } from 'Colugo/interfaces/lobby/discover/enums';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { EnumHelper } from 'Colugo/utility/helpers';

type Props = {
  block: IBlock;
  onChange?: (block: IBlock) => void;
};

function BlockTypeSelector(props: Props) {
  // Props
  const { block, onChange } = props;

  // State
  const [isLayoutSelectorOpen, setIsLayoutSelectorOpen] =
    useState<boolean>(false);

  const layoutFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(Layout);

  return (
    <div className="">
      <span className="font-semibold">{'Layout:'}</span>
      <FlyoutMenu
        activeKey={block.layout! - 1}
        isEqual={isEqual}
        size="sm"
        isMenuOpen={isLayoutSelectorOpen}
        setIsMenuOpen={setIsLayoutSelectorOpen}
        items={layoutFlyoutMenuItems}
        onChange={(e) =>
          onChange?.({
            ...block,
            layout: e.key! + 1
          })
        }
      />
    </div>
  );
}

export default BlockTypeSelector;
