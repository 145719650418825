import TagSelector from '../blocks/TagSelector';
import React from 'react';
import { useReqListTags } from 'Colugo/operations/tags';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';

type Props = {
  block: IBlock;
  onChange?: (block: IBlock) => void;
};

function BlockTagsSelector(props: Props) {
  // Props
  const { block, onChange } = props;

  const { data: tags } = useReqListTags();

  function getSelectedBlockTags() {
    if (!tags) {
      return;
    }
    return block.tagIds?.map((tagId) => {
      const tag = tags.find((t) => t.id === tagId);
      return tag!;
    });
  }

  function handleAddTag(tagId: string) {
    onChange?.({
      ...block,
      tagIds: [...(block.tagIds || []), tagId]
    });
  }

  function handleRemoveTag(tagId: string) {
    const tagIds = block.tagIds?.filter((t) => t !== tagId);
    onChange?.({
      ...block,
      tagIds: tagIds
    });
  }

  return (
    <div className="mt-2">
      <span className="font-semibold">{'Tags:'}</span>
      <TagSelector
        tags={tags || []}
        selectedTags={getSelectedBlockTags() ?? []}
        onConfirmNewTag={handleAddTag}
        onClickTagDeleteIcon={handleRemoveTag}
      />
    </div>
  );
}

export default BlockTagsSelector;
